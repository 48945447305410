import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, parceriaAcaoStore, cityStore, mainStore, parceriaStore, } from '@/utils/store-accessor';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/pt-br.js';
import _ from 'lodash';
import { format } from 'date-fns';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import CriarOcorrenciaUpdatedComponent from '@/components/CriarOcorrenciaUpdatedComponent.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import { ValidationObserver, ValidationProvider, } from 'vee-validate';
let PlanejamentoComercial = class PlanejamentoComercial extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Segunda-feira', value: 'segunda' },
            { text: 'Terça-feira', value: 'terca' },
            { text: 'Quarta-feira', value: 'quarta' },
            { text: 'Quinta-feira', value: 'quinta' },
            { text: 'Sexta-feira', value: 'sexta' },
            { text: 'Sábado', value: 'sabado' },
            { text: 'Domingo', value: 'domingo' },
        ];
        this.attribution = '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>';
        this.bounds = null;
        this.center = null;
        this.colaboradores = [];
        this.events = [];
        this.items = [];
        this.loading = true;
        this.search = '';
        this.selectedEvent = {};
        this.selectPeriodDate = null;
        this.showDialog = false;
        this.showDialogAcao = false;
        this.tab = 'tab-planejamento';
        this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.weeksOffset = 0;
        this.zoom = 12;
        this.selectedTab = 4;
        this.acaoSelected = [];
        this.entidadeSelected = null;
        this.dataAcao = null;
        this.horario_inicio = null;
        this.horario_fim = null;
        this.descricao = '';
        this.selectedParceriaId = null;
        this.parcerias = [];
        this.selectedAcao = null;
        this.stepOneComplete = false;
        this.stepTwoComplete = false;
        this.stepThreeComplete = false;
        this.stepFourComplete = false;
        this.dialogCriarOcorrencia = false;
        this.entidadeTipoOcorrenciaAtual = '';
        this.parceria = 'parceria';
        this.classe = {
            0: 'tarefa',
            1: 'urna',
            2: 'pesquisa',
            3: 'abordagem',
        };
    }
    async adicionarColaborador() {
        if (this.entidadeSelected && this.entidadeSelected.id) {
            this.colaboradores.push({
                colaborador_id: this.entidadeSelected.id,
                nome: this.entidadeSelected.nome,
            });
            this.entidadeSelected = null;
            // @ts-ignore
            this.checkStepCompletion(); // Reavalia as etapas após adicionar um colaborador
        }
    }
    async removeColaborador(colaborador) {
        const editedIndex = this.colaboradores.indexOf(colaborador);
        this.colaboradores.splice(editedIndex, 1);
    }
    get filteredEvents() {
        return _.filter(this.events, (item) => item.parceria_latitude_longitude);
    }
    get parceriasFiltradas() {
        return this.parcerias.filter((parceria) => parceria.status_id === 4);
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed['Quadro CDA']) {
            return userToolStore.userToolsIndexed['Quadro CDA'][0]
                .allow_view;
        }
    }
    parceriaLatLng(latLong) {
        if (latLong) {
            const pieces = latLong.split(',');
            return [+pieces[0], +pieces[1]];
        }
    }
    zoomUpdated(zoom) {
        this.zoom = zoom;
    }
    centerUpdated(center) {
        this.center = center;
    }
    boundsUpdated(bounds) {
        this.bounds = bounds;
    }
    async mapReady() {
        // @ts-ignore
        this.map = this.$refs.myMap.mapObject;
        // @ts-ignore
        this.screenshoter = new SimpleMapScreenshoter({
            domtoimageOptions: { quality: 1.0 },
            mimeType: 'image/jpeg',
            preventDownload: true,
            hidden: true,
            cropImageByInnerWH: true,
        }).addTo(this.map);
    }
    ajustaHora(hora) {
        if (hora) {
            const formated_hora = format(hora, 'HH:mm:ss').split(':');
            return `${formated_hora[0]}:${formated_hora[1]}`;
        }
    }
    ajustaHoraAcao(val, inicio) {
        if (val == '') {
            return;
        }
        const tempHora = val.replace(':', '');
        let horaFinal = tempHora;
        if (tempHora.length < 4) {
            horaFinal = tempHora + '0'.repeat(4 - tempHora.length);
        }
        if (inicio) {
            this.horario_inicio = horaFinal.replace(/^(\d{2})(\d{2}).*/, '$1:$2');
        }
        else {
            this.horario_fim = horaFinal.replace(/^(\d{2})(\d{2}).*/, '$1:$2');
        }
    }
    async close() {
        this.showDialogAcao = false;
        this.reset();
    }
    async reset() {
        this.dataAcao = null;
        this.acaoSelected = [];
        this.descricao = '';
        this.colaboradores = [];
        this.entidadeSelected = null;
        // @ts-ignore
        this.$refs.searchEntidadeComponent.selectedEntidade = {};
        // @ts-ignore
        await this.$refs.dataAcao.$refs.observer.reset();
    }
    async save() {
        // Validações de data e formulário
        // @ts-ignore
        const isValidDate = await this.$refs.dataAcao.$refs.observer.validate();
        const isValid = await this.$refs.observer.validate();
        const isValidColaborador = this.colaboradores.length > 0;
        const isValidAcao = !!this.acaoSelected; // Verifica se uma ação foi selecionada
        // Verifica se todas as validações foram aprovadas
        if (!isValidDate || !isValid || !isValidColaborador || !isValidAcao) {
            // Mostra mensagens de erro específicas
            if (!isValidColaborador) {
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: ['Você precisa adicionar ao menos um colaborador!'],
                });
            }
            if (!isValidAcao) {
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: ['Você precisa escolher uma ação!'],
                });
            }
            return;
        }
        const obj = {
            // @ts-ignore
            id: this.editedItem?.id,
            parceria_id: this.selectedParceriaId,
            descricao: this.descricao,
            colaboradores: this.colaboradores,
            acao_id: this.acaoSelected,
            horario_inicio: this.horario_inicio,
            horario_fim: this.horario_fim,
            data: this.dataAcao,
        };
        await parceriaAcaoStore.updateParceriaAcao(obj);
        this.initialize();
        this.reset();
        this.close();
    }
    async initialize() {
        this.loading = true;
        this.items = await parceriaAcaoStore.getParceriaAcoes(this.selectedParceriaId);
        this.loading = false;
    }
    async checkStepCompletion() {
        this.stepOneComplete = !!this.selectedParceriaId;
        this.stepTwoComplete = this.stepOneComplete && !!this.acaoSelected;
        this.stepThreeComplete =
            this.stepTwoComplete && this.colaboradores.length > 0;
        this.stepFourComplete =
            this.horario_fim && this.horario_inicio && this.dataAcao;
    }
    onResize() {
        // @ts-ignore
        this.$refs.myMap.mapObject._onResize();
    }
    async onEventClick(event, e) {
        this.selectedEvent = event;
        this.showDialog = true;
        e.stopPropagation();
    }
    onCancel() {
        this.showDialog = false;
        this.dialogCriarOcorrencia = false;
        this.showDialogAcao = false;
    }
    async prev() {
        this.loading = true;
        this.weeksOffset = this.weeksOffset - 1;
        this.selectPeriodDate = await this.getWeek(this.weeksOffset);
        this.items = await parceriaAcaoStore.getParceriaAcoesPlanejamentoComercial({
            data_inicio: this.selectPeriodDate[0],
            data_fim: this.selectPeriodDate[1],
        });
        this.mountEvents();
        this.loading = false;
    }
    async next() {
        this.loading = true;
        this.weeksOffset = this.weeksOffset + 1;
        this.selectPeriodDate = await this.getWeek(this.weeksOffset);
        this.items = await parceriaAcaoStore.getParceriaAcoesPlanejamentoComercial({
            data_inicio: this.selectPeriodDate[0],
            data_fim: this.selectPeriodDate[1],
        });
        this.mountEvents();
        this.loading = false;
    }
    async getWeekBounds(date) {
        // Create a new Date object for the given date
        const currentDate = date;
        // Get the day of the week (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
        const dayOfWeek = currentDate.getDay();
        // Calculate the difference to get to the first day of the week (Sunday)
        const firstDayDiff = dayOfWeek;
        // Calculate the difference to get to the last day of the week (Saturday)
        const lastDayDiff = 6 - dayOfWeek;
        // Calculate the first day of the week
        const firstDayOfWeek = new Date(currentDate);
        firstDayOfWeek.setDate(currentDate.getDate() - firstDayDiff);
        // Calculate the last day of the week
        const lastDayOfWeek = new Date(currentDate);
        lastDayOfWeek.setDate(currentDate.getDate() + lastDayDiff);
        // Return the first and last day of the week
        return {
            firstDay: firstDayOfWeek,
            lastDay: lastDayOfWeek,
        };
    }
    async getWeek(amountWeeks) {
        const curr = new Date();
        curr.setDate(curr.getDate() + 7 * amountWeeks);
        const dayOfWeek = curr.getDay();
        const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
        const monday = new Date(curr);
        monday.setDate(curr.getDate() + diffToMonday);
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        return [
            monday.toISOString().split('T')[0],
            sunday.toISOString().split('T')[0],
        ];
    }
    mountEvents() {
        this.events = [];
        _.forEach(this.items, (item) => {
            let content = ``;
            if (item.parceria_nome) {
                content += `${item.parceria_nome}`;
            }
            if (item.colaboradores) {
                _.forEach(item.colaboradores, (colaborador) => {
                    const primeiro_nome = colaborador.full_name.split(' ')[0];
                    content += `<i><br/>${primeiro_nome}</i>`;
                });
            }
            let title = _.find(
            // @ts-ignore
            this.parceriaAcaoOptions(), (i) => i.id == item.acao_id);
            if (title) {
                title = title.name;
            }
            else {
                title = 'Tarefa';
            }
            if (item.status_id == 2) {
                title = title + '<span style="color: green">&#x2714</span>';
            }
            this.events.push({
                start: `${item.data} ${item.horario_inicio}`,
                end: `${item.data} ${item.horario_fim}`,
                timed: true,
                class: this.classe[item.acao_id],
                title,
                id: item.id,
                descricao: item.descricao,
                parceria: item.parceria_nome,
                colaboradores: item.colaboradores,
                content,
                status_id: item.status_id,
                acao_id: item.acao_id,
                parceria_id: item.parceria_id,
                parceria_latitude_longitude: item.parceria_latitude_longitude,
            });
        });
    }
    async marcarExecutada(item) {
        this.$swal({
            title: 'Confirma a execução da ação?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (await parceriaAcaoStore.updateStatusParceriaAcao({
                    id: this.selectedEvent.id,
                    status_id: 2,
                })) {
                    this.selectedEvent.status_id = 2;
                }
            }
        });
    }
    async mounted() {
        this.loading = true;
        this.selectPeriodDate = await this.getWeek(0);
        this.items = await parceriaAcaoStore.getParceriaAcoesPlanejamentoComercial({
            data_inicio: this.selectPeriodDate[0],
            data_fim: this.selectPeriodDate[1],
        });
        this.mountEvents();
        const city = await cityStore.getCityByCodigoMunicipio(this.userProfile.company.city_codigo_municipio_completo);
        this.center = [+city.latitude, +city.longitude];
        this.parcerias = await parceriaStore.getParcerias();
        this.loading = false;
    }
};
PlanejamentoComercial = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            OpenNewTabComponent,
            LTileLayer,
            LMap,
            VueCal,
            LMarker,
            LPopup,
            ButtonComponent,
            SearchEntidadeComponent,
            DateFieldComponent,
            ValidationObserver,
            ValidationProvider,
            CriarOcorrenciaUpdatedComponent,
            CustomTabsComercialComponent,
        },
    })
], PlanejamentoComercial);
export default PlanejamentoComercial;
